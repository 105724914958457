<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const breadcrumbItems = [
  { label: t('services.title'), href: '/' },
  { label: t('serviceHistory.title'), href: '/user/service-history' },
];

const demoItems = [
  {
    title: 'Grúa',
    originAddress: 'Carlos Wilson 90, Providencia',
    destinationAddress: 'Mujica 300, Ñuñoa',
    status: 'requested',
    date: new Date('2024-08-15T16:10:00'),
  },
  {
    title: 'Cambio de neumático',
    originAddress: 'Carlos Wilson 90, Providencia',
    status: 'finished',
    date: new Date('2024-07-05T12:25:00'),
  },
  {
    title: 'Taxi',
    originAddress: 'Carlos Wilson 90, Providencia',
    destinationAddress: 'Mujica 300, Ñuñoa',
    status: 'cancelled',
    date: new Date('2024-02-12T09:36:00'),
  },
];
</script>

<template>
  <div class="mx-auto w-full px-6 pt-10 sm:px-16 md:max-w-screen-2xl md:pt-14">
    <base-breadcrumb
      :items="breadcrumbItems"
      class="mb-8 md:mb-12"
    />
    <h1 class="mb-2 text-2xl font-semibold text-gray-800 md:mb-4 md:text-4xl">
      {{ $t('serviceHistory.title') }}
    </h1>
    <p class="mb-8 text-sm font-medium text-gray-500 md:mb-12 md:text-lg">
      {{ $t('serviceHistory.requestedServices', demoItems.length) }}
    </p>
    <div class="mb-8 flex flex-col gap-6 md:gap-8">
      <div
        v-for="item in demoItems"
        :key="item.title"
        class="flex flex-col gap-4 rounded-lg bg-gray-100 px-4 py-6 md:p-9"
      >
        <p class="text-sm font-semibold text-gray-800 md:text-lg">
          {{ $t('serviceHistory.requestDate') }} <br class="md:hidden"> {{ $d(item.date, 'long') }}
        </p>
        <div class="flex flex-col gap-4 md:flex-row md:items-start md:gap-6">
          <img
            src="https://placehold.co/240x132"
            class="aspect-[280/120] w-full shrink-0 overflow-hidden rounded-lg object-cover md:aspect-[240/144] md:w-auto"
            alt=""
          >
          <div class="flex w-full flex-col items-start gap-2">
            <base-mok-tag
              :label="$t(`serviceHistory.statuses.${item.status}.name`)"
              :color="item.status === 'cancelled' ? 'grey' : 'primary'"
              variant="secondary"
            />
            <h2 class="text-lg font-semibold text-gray-800 md:text-3xl">
              {{ item.title }}
            </h2>
            <p class="text-sm text-gray-500 md:text-base">
              {{ $t('serviceHistory.originAddress') }}: {{ item.originAddress }}
              <template v-if="item.destinationAddress">
                <br>
                {{ $t('serviceHistory.destinationAddress') }}: {{ item.destinationAddress }}
              </template>
            </p>
          </div>
          <base-mok-button
            class="w-full shrink-0 self-center md:ml-auto md:w-auto"
            :label="$t('serviceHistory.openDetailButton')"
            href="/user/services_history/123"
          />
        </div>
      </div>
    </div>
  </div>
</template>
