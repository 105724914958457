<script setup lang="ts">
import { PhCaretDown } from '@phosphor-icons/vue';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t, d } = useI18n();
const { greaterOrEqual } = useBreakpoints(breakpointsTailwind);
const mdOrLarger = greaterOrEqual('md');

const breadcrumbItems = [
  { label: t('services.title'), href: '/' },
  { label: t('serviceHistory.title'), href: '/user/services_history' },
  { label: t('serviceHistory.show.title') },
];

const steps = [
  { id: 'requested', status: 'finished', date: new Date('2024-08-15T16:10:00') },
  { id: 'assigned', status: 'inProgress' },
  { id: 'started', status: 'pending' },
  { id: 'finished', status: 'pending' },
];

const showDetails = ref(false);
const responsiveShowDetails = computed(() => mdOrLarger.value || showDetails.value);

const demoService = {
  serviceName: 'Grúa',
  scheduledDate: new Date('2024-08-15T18:30:00'),
  originAddress: 'Carlos Wilson 90, Providencia, Santiago',
  destinationAddress: 'Mujica 300, Ñuñoa, Santiago',
  caseId: '4002089',
  userPhoneNumber: '+(56) 984535873',
  contactCenterPhone: '+56 9 6607 4635',
};

const serviceInfo = [
  {
    term: 'serviceHistory.show.serviceInfo.date',
    detail: d(demoService.scheduledDate, 'long'),
  },
  {
    term: 'serviceHistory.show.serviceInfo.caseId',
    detail: demoService.caseId,
  },
  {
    term: 'serviceHistory.show.serviceInfo.originAddress',
    detail: demoService.originAddress,
  },
  {
    term: 'serviceHistory.show.serviceInfo.destinationAddress',
    detail: demoService.destinationAddress,
  },
  {
    term: 'serviceHistory.show.serviceInfo.userPhoneNumber',
    detail: demoService.userPhoneNumber,
  },
];

const providerInfo = [
  {
    term: 'serviceHistory.show.providerInfo.name',
    detail: 'Grúa A.T SPA',
  },
  {
    term: 'serviceHistory.show.providerInfo.resourceName',
    detail: 'Diego Abarca Toro',
  },
  {
    term: 'serviceHistory.show.providerInfo.phoneNumber',
    detail: '+(56) 985689325',
  },
];
</script>

<template>
  <div class="mx-auto w-full px-6 pt-10 sm:px-16 md:max-w-screen-2xl md:pt-14">
    <base-breadcrumb
      :items="breadcrumbItems"
      class="mb-8 md:mb-12"
    />
    <div class="mb-8 flex flex-col">
      <div class="mb-8 flex flex-col gap-4 rounded-lg bg-gray-100 px-4 py-6 md:mb-12 md:flex-row md:items-start md:gap-16 md:p-9">
        <div class="flex items-center gap-4 md:flex-col-reverse md:items-start">
          <img
            src="https://placehold.co/456x318"
            class="aspect-square h-16 overflow-hidden rounded-lg object-cover md:aspect-auto md:h-full md:max-h-[318px]"
            alt=""
          >
          <div>
            <h1 class="text-xl font-semibold text-gray-800">
              {{ demoService.serviceName }}
            </h1>
            <p class="text-sm text-gray-500">
              {{ $d(demoService.scheduledDate, 'long') }}
            </p>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="mb-6 flex">
            <base-mok-stepper
              class="mr-6"
              :steps="steps"
              :current-step="1"
              direction="vertical"
            />
            <ul class="flex flex-col justify-between">
              <li
                v-for="step in steps"
                :key="step.id"
                class="my-4 flex flex-col gap-1"
              >
                <span
                  v-if="step.status !== 'finished'"
                  class="text-xs"
                  :class="step.status === 'inProgress' ? ' font-semibold text-primary-600' : 'text-gray-400'"
                >
                  {{ $t(`serviceHistory.show.stepStatuses.${step.status}`) }}
                </span>
                <span
                  v-else
                  class="text-xs text-gray-500"
                >
                  {{ $d(step.date as Date, 'long') }}
                </span>
                <p class="text-base font-semibold text-gray-800">
                  {{ $t(`serviceHistory.statuses.${step.id}.name`) }}
                </p>
                <p
                  v-if="responsiveShowDetails"
                  class="overflow-hidden text-xs text-gray-500"
                >
                  {{ $t(`serviceHistory.statuses.${step.id}.description`) }}
                </p>
              </li>
            </ul>
          </div>
          <base-mok-button
            v-if="!mdOrLarger"
            :label="showDetails ? $t('serviceHistory.show.hideDetails') : $t('serviceHistory.show.showDetails')"
            theme="link"
            :icon="PhCaretDown"
            icon-position="right"
            @click="showDetails = !showDetails"
          />
        </div>
      </div>
      <div
        v-if="demoService.contactCenterPhone"
        class="mb-8 rounded-lg bg-primary-500 p-4 text-center text-white md:mb-12 md:p-6"
      >
        <h2 class="mb-2 text-xl font-semibold md:text-3xl">
          {{ $t('serviceHistory.show.helpBox.title') }}
        </h2>
        <i18n-t
          tag="p"
          keypath="serviceHistory.show.helpBox.text"
          class="mb-4 text-sm font-medium md:text-lg"
        >
          <template #newline>
            <br>
          </template>
          <template #contactPhone>
            {{ demoService.contactCenterPhone }}
          </template>
        </i18n-t>
        <base-mok-button
          variant="secondary"
          :size="mdOrLarger ? 'medium' : 'small'"
          :label="$t('serviceHistory.show.helpBox.ctaButton')"
        />
      </div>
      <h2 class="mb-4 text-xl font-medium text-gray-800 md:mb-6 md:text-3xl">
        {{ $t('serviceHistory.show.serviceInfo.title') }}
      </h2>
      <div class="mb-8 flex flex-col gap-4 rounded-lg bg-gray-100 p-4 md:mb-12 md:p-9">
        <dl class="grid gap-x-8 text-sm md:grid-cols-2">
          <div
            v-for="(info) in serviceInfo"
            :key="info.term"
            class="mb-3 last:mb-0"
          >
            <dt class="px-2 text-gray-500">
              {{ $t(info.term) }}
            </dt>
            <dd
              class="truncate border-b border-gray-300 px-2 pb-3 font-semibold text-gray-800"
            >
              {{ info.detail }}
            </dd>
          </div>
        </dl>
      </div>
      <h2 class="mb-4 text-xl font-medium text-gray-800 md:mb-6 md:text-3xl">
        {{ $t('serviceHistory.show.providerInfo.title') }}
      </h2>
      <div class="mb-8 flex flex-col gap-4 rounded-lg bg-gray-100 p-4 md:mb-12 md:p-9">
        <dl class="grid gap-x-8 text-sm md:grid-cols-2">
          <div
            v-for="(info, i) in providerInfo"
            :key="info.term"
          >
            <dt class="px-2 text-gray-500">
              {{ $t(info.term) }}
            </dt>
            <dd
              class="truncate border-b border-gray-300 px-2 pb-3 font-semibold text-gray-800"
              :class="{ 'mb-3': i !== providerInfo.length - 1 }"
            >
              {{ info.detail }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>
