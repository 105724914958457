<script setup lang="ts">
import { inject } from 'vue';

import useSiteVariables from '@/composables/useSiteVariables';
import type { LoyaltyInfoModalNames } from '@/types/loyalty-info-modal-names';
import scrollToElement from '@/utils/scroll-to-element';

import NavbarMenuDrawerButton from './navbar-menu-drawer-button.vue';
import UserAppsDrawer from '../../user-apps-drawer.vue';

interface Props {
  logoUrl?: string;
  serviceRedemptionsPath: string;
  editUserPath?: string;
}

withDefaults(defineProps<Props>(), {
  logoUrl: undefined,
  editUserPath: undefined,
});

const { hasLoyalty, isRedeMaisSaude, featureFlags } = useSiteVariables();
const openLoyaltyModal = inject('openLoyaltyModal') as (key: LoyaltyInfoModalNames) => void;

const emits = defineEmits<{(e: 'close'): void}>();

function emitClose() {
  emits('close');
}

function redirectToRoot() {
  window.location.replace(window.location.origin);
}

function redirectTo(path: string) {
  emitClose();
  window.location.assign(path);
}

function closeAndScrollTo(id: string) {
  emitClose();
  scrollToElement(id, redirectToRoot);
}

function openLoyaltyInfoModal() {
  emitClose();
  openLoyaltyModal('loyalty-info-modal');
}
</script>
<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<template>
  <div class="fixed right-0 top-0 z-20 flex size-full flex-col justify-between overflow-y-auto bg-white pt-16 shadow-md md:w-96">
    <div>
      <img
        class="absolute left-6 top-4 h-12 md:hidden"
        alt="logo"
        :src="logoUrl"
      >
      <base-svg
        data-testid="close-button"
        name="close"
        class="absolute right-6 top-6 size-4 cursor-pointer fill-current"
        @click="emitClose"
      />
      <div class="mb-4 px-6 py-2">
        <slot name="profile" />
      </div>
      <user-apps-drawer
        data-testid="user-apps-drawer"
      />
      <navbar-menu-drawer-button
        v-if="isRedeMaisSaude"
        data-testid="rms-services-history-button"
        label-i18n-path="sections.navbars.shared.rmsServicesHistory"
        @click="redirectTo(serviceRedemptionsPath)"
      />
      <navbar-menu-drawer-button
        v-else-if="featureFlags?.serviceHistory"
        data-testid="service-history-button"
        label-i18n-path="sections.navbars.shared.serviceHistory"
        @click="redirectTo('/user/services_history')"
      />
      <template v-if="hasLoyalty">
        <navbar-menu-drawer-button
          data-testid="rewardable-section-button"
          label-i18n-path="sections.navbars.shared.winPoints"
          @click="closeAndScrollTo('#loyaltyRewardableContent')"
        />
        <navbar-menu-drawer-button
          data-testid="redeemable-section-button"
          label-i18n-path="sections.navbars.shared.redemptionStore"
          @click="closeAndScrollTo('#loyaltyRedeemableContent')"
        />
        <navbar-menu-drawer-button
          data-testid="loyalty-info-button"
          label-i18n-path="sections.navbars.shared.pointsInformation"
          left-icon="trophy"
          right-icon="arrow-right"
          @click="openLoyaltyInfoModal"
        />
      </template>
    </div>
    <div class="w-full">
      <navbar-menu-drawer-button
        v-if="editUserPath"
        data-testid="edit-profile-button"
        label-i18n-path="sections.navbars.shared.editProfile"
        left-icon="user-circle-check"
        @click="redirectTo(editUserPath)"
      />
      <a
        href="/users/auth/openid_connect/logout_start"
        class="flex w-full items-center border border-slate-50 px-6 py-5 text-left hover:bg-gray-100"
      >
        <base-svg
          name="on-off"
          class="mr-3 size-5 fill-current"
        />
        {{ $t('sections.navbars.shared.logOut') }}
      </a>
    </div>
  </div>
  <button
    class="left-0 top-0 hidden h-full bg-black opacity-60 md:fixed md:z-10 md:block md:w-full"
    @click="emitClose"
  />
</template>
